<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block" v-if="loading === true">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-block" v-else>
      <form @submit.prevent="storeCompany">
        <div class="modal-header">
          <h3>{{ editMode ? 'Edit Company' : 'Add New Company' }}</h3>
        </div>
        <div class="modal-body">
          <div class="modal-body-container">
            <div class="mt-2">
              <label>Select Company Type</label>
              <div class="mb-3">
                <div class="d-flex">
                  <span class="check-btn" :class="{ active: company.type === 'escrow' }"
                        @click="company.type = 'escrow'">Escrow</span>
                  <span class="check-btn" :class="{ active: company.type === 'title' }"
                        @click="company.type = 'title'">Title</span>
                </div>
                <span class="error-msg" v-for="(error, index) in getError('type', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
            </div>

            <div class="row w-75">
              <div class="form-group">
                <label for="name">Company Name</label>
                <input type="text" id="name" v-model="company.name" class="form-control" autocomplete="off" required>
                <span class="error-msg" v-for="(error, index) in getError('name', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="row mb-4 w-75">
              <div class="col-12">
                <div class="form-group">
                  <label for="address">Address</label>
                  <input type="text" id="address" v-model="company.address" class="form-control"
                        placeholder="e.g. 123 W Dallas St, Suite 100" autocomplete="off">
                </div>
              </div>
            </div>
            <div class="row mb-4 w-75">
              <div class="col-md-8">
                <div class="form-group">
                  <label for="cityState">City & State</label>
                  <input id="cityState" class="form-control col-6" v-model="company.city"
                        placeholder="e.g New York, NY" autocomplete="off">
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="zip">Zip</label>
                  <input id="zip" class="form-control" v-model="company.zip"
                        placeholder="e.g 10001" autocomplete="off">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="row mb-4 w-75">
              <div class="col-8">
                <div class="form-group">
                  <label for="licenseNumber">License Number</label>
                  <input id="licenseNumber" class="form-control" v-model="company.licenseNumber"
                        placeholder="e.g New York, NY" autocomplete="off">
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="state">Licensed State</label>
                  <MultiSelect v-model="company.state"
                              :options="states"
                              :searchable="true"
                              :close-on-select="true"
                              :show-labels="false"
                              :allow-empty="false"
                              id="state"
                              placeholder="Select state"/>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body-container border-0">
            <div class="row w-75 mb-4">
              <div class="form-group">
                <label for="settlementAgent">Settlement Agent</label>
                <input type="text" id="settlementAgent" v-model="company.settlementAgentName" class="form-control"
                      placeholder="Settlement Agent Name" autocomplete="off" required>
                <span class="error-msg" v-for="(error, index) in getError('settlementAgentName', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="row w-75 mb-4">
              <div class="form-group">
                <label for="phone">Phone</label>
                <input id="phone" class="form-control" v-model="company.phone"
                      placeholder="Phone Number" autocomplete="off">
              </div>
            </div>
            <div class="row w-75 mb-4">
              <div class="form-group">
                <label for="email">Email</label>
                <input id="email" type="email" class="form-control" v-model="company.email"
                      placeholder="Email Address" autocomplete="off" required>
                <span class="error-msg" v-for="(error, index) in getError('email', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="row w-75">
              <div class="form-group">
                <label for="note">Note</label>
                <textarea id="note" class="form-control" rows="7" v-model="company.note"
                          placeholder="Put additional contacts if needed ..." autocomplete="off"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-start">
          <base-button :title="editMode ? 'Save' : 'Add Company'" action="secondary-default" :loading="btnSpinner" type="submit" />
          <base-button title="Discard" type="reset" action="secondary" @click-btn="$emit('close')" class="ms-2" />
          <base-button v-if="( isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT') ) && id !== ''"
                       title="Make inactive"
                       type="reset"
                       action="secondary"
                       @click-btn="deleteCompany"
                       class="ms-2" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect'
import BaseButton from '../../components/BaseButton.vue'

export default {
  name: "CompanyForm",
  props: { id: { required: false, default: '' } },
  components: {MultiSelect, BaseButton},
  data() {
    return {
      editMode: false,
      loading: true,
      btnSpinner: false,
      errors: [],
      states: [],
      company: {
        type: 'escrow',
        name: '',
        address: '',
        city: '',
        zip: '',
        licenseNumber: '',
        state: '',
        settlementAgentName: '',
        phone: null,
        email: null,
        openOrderEmail: '',
        note: ''
      }
    }
  },
  methods: {
    storeCompany() {
      this.errors = []
      this.btnSpinner = true

      let url = this.editMode
        ? '/api/v1/escrow-companies/edit/' + this.id
        : '/api/v1/escrow-companies/new'

      this.$http.post(url, this.company)
        .then((res) => {
          this.$emit('addedCompany', {
            value: res.data.id,
            label: res.data.name
          })
          this.$emit('close', true)
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.errors = err.response.data.errors
          }
          this.btnSpinner = false
        })
    },
    getCompany() {
      this.$http.get(`/api/v1/escrow-companies/show/${this.id}`)
        .then((res) => {
          this.company = res.data
          this.loading = false
        })
    },
    getStates() {
      this.$http.get(`/api/v1/form/states`)
        .then((res) => {
          res.data.states.unshift('Nationwide');
          this.states = res.data.states
        })
    },
    handleState(option) {
      this.company.licenseState = option
    },
    deleteCompany() {
      if (confirm('Are you sure?')) {
        this.$http.delete(`/api/v1/escrow-companies/delete/${this.id}`)
          .then(() => this.$emit('close', true))
      }
    }
  },
  mounted() {
    this.editMode = Number.isInteger(this.id);
    this.getStates()
    if(this.editMode) {
      this.getCompany()
    } else {
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.form-group {
  max-width: none;
}
.btn-cancel {
  padding: 0 50px;
}
.modal {
  form {
    position: relative;
    width: 100%;
  }
}
.modal-body-container::v-deep .multiselect {

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38,111,229,0.5);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__tags {
    padding: 8px 40px 0 8px;
    transition: all .3s;
    &:hover {
      border: 1px solid rgba(38,111,229,0.2);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__select {
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }
  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }
  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}

</style>
