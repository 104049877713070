<template>
  <div class="modal modal-center" id="export">
    <div class="modal-bg" @click="$emit('close', null)"></div>

    <div class="modal-block">
      <div class="modal-header">
        <h3>Loan Submission Checklist</h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container border-bottom-0">
          <div class="full-width form-check">
            <div>
              <input id="fieldFully" class="form-check-input" type="checkbox" v-model="checkList.fullyExecuted"/>
            </div>
            <label class="mb-0 ms-2" for="fieldFully">1. Fully Executed Contract in the file.</label>
          </div>
          <div class="full-width form-check mt-3">
            <div>
              <input id="fieldEscrow" class="form-check-input" type="checkbox" v-model="checkList.escrowContract"/>
            </div>
            <label class="mb-0 ms-2" for="fieldEscrow">2. Escrow Contact is in the file.</label>
          </div>
          <div class="full-width form-check mt-3">
            <div>
              <input id="taxBill" class="form-check-input" type="checkbox" v-model="checkList.taxBill" />
            </div>
            <label class="mb-0 ms-2" for="taxBill">3. Tax Bill is in file</label>
          </div>
          <div class="full-width form-check mt-3">
            <div>
              <input id="propertyProfile" class="form-check-input" type="checkbox" v-model="checkList.propertyProfile" />
            </div>
            <label class="mb-0 ms-2" for="propertyProfile">4. Property Profile Report is in the file.</label>
          </div>
          <div class="full-width form-check mt-3">
            <div>
              <input id="loanProgram" class="form-check-input" type="checkbox" v-model="checkList.loanProgram" />
            </div>
            <label class="mb-0 ms-2" for="loanProgram">5. Loan Program is selected in Lending Pad.</label>
          </div>
          <div class="mt-3">
            <div class="full-width form-check">
              <div>
                <input id="fieldUpdateLendingPad" class="form-check-input" type="checkbox" v-model="checkList.updateLendingPad"/>
              </div>
              <label class="mb-0 ms-2" for="fieldUpdateLendingPad">7. Update Lending Pad with the following:</label>
            </div>
            <ol type="a">
              <li>Sales Price</li>
              <li>Seller Credits</li>
              <li>Property Taxes</li>
              <li>REO page must be completed (if applicable).</li>
            </ol>
          </div>
          <div class="mt-3">
            <div class="full-width form-check">
              <div>
                <input id="fieldVerifyBorrower" class="form-check-input" type="checkbox" v-model="checkList.verifyBorrower"/>
              </div>
              <label class="mb-0 ms-2" for="fieldVerifyBorrower">7. Verify borrower information:</label>
            </div>
            <ol type="a">
              <li>Phone and email are in the file.</li>
              <li>Verify that the DOB in file matches the ID.</li>
            </ol>
          </div>
          <div class="mt-3">
            <div class="full-width form-check">
              <div>
                <input id="fieldReviewCredit" class="form-check-input" type="checkbox" v-model="checkList.reviewCredit"/>
              </div>
              <label class="mb-0 ms-2" for="fieldReviewCredit">8. Review the credit report:</label>
            </div>
            <ol type="a">
              <li>Confirm report is not expired.</li>
              <li>Check if there are any open mortgages.</li>
              <li>Mortgage statement must be in the file.</li>
            </ol>
          </div>
          <div class="mt-3">
            <div class="full-width form-check">
              <div>
                <input id="fieldVALoans" class="form-check-input" type="checkbox" v-model="checkList.VALoans"/>
              </div>
              <label class="mb-0 ms-2" for="fieldVALoans">9. VA Loans must have the following documents:</label>
            </div>
            <ol type="a">
              <li>Obtain COE</li>
              <li>Transfer orders (PCS orders) must be in file (if applicable).</li>
              <li>VA Residual Income Calculator completed.</li>
              <li>Paycheck City Calculator completed.</li>
            </ol>
          </div>
          <div class="mt-3">
            <div class="full-width form-check">
              <div>
                <input id="fieldFollowingDocs" class="form-check-input" type="checkbox" v-model="checkList.followingDocs"/>
              </div>
              <label class="mb-0 ms-2" for="fieldFollowingDocs">10. Following documents must be uploaded to the file.</label>
            </div>
            <ol type="a">
              <li>Updated paystubs.</li>
              <li>All income docs needed.</li>
              <li>Updated bank statements needed for Cash to Close or Reserves.</li>
            </ol>
          </div>
          <div class="mt-3">
            <div class="full-width form-check">
              <div>
                <input id="fieldFollowingFees" class="form-check-input" type="checkbox" v-model="checkList.followingFees"/>
              </div>
              <label class="mb-0 ms-2" for="fieldFollowingFees">11. Following Fees must be updated under Disclosure tab.</label>
            </div>
            <ol type="a">
              <li>Loan is priced and discount points updated if applicable.</li>
              <li>Update Underwriting Fee Section A.</li>
              <li>Update Processing Fee to Section A or B (B if brokered).</li>
              <li>Update the Section B Appraisal Box.</li>
              <li>Update Section C for Title.</li>
              <li>Update Section F and G with the correct Pre-Paid Interest and Impounds.</li>
              <li>Client is clear on their Cash to Close, Rate and Payment.</li>
            </ol>
          </div>
          <div class="mt-3">
            <div class="full-width form-check">
              <div>
                <input id="runBot" class="form-check-input" type="checkbox" v-model="checkList.runBot"/>
              </div>
              <label class="mb-0 ms-2" for="runBot">12. Run Bolt (If UWM).</label>
            </div>
            <ol type="a"></ol>
          </div>
          <div class="mt-3">
            <div class="full-width form-check">
              <div>
                <input id="isFHACondo" class="form-check-input" type="checkbox" v-model="checkList.isFHACondo"/>
              </div>
              <label class="mb-0 ms-2" for="isFHACondo">13. If FHA/VA Condo - Is Condo Approved?</label>
            </div>
            <ol type="a"></ol>
          </div>
          <div class="mt-4">
            <div class="full-width form-check">
              <div>
                <input id="fieldTotalAgree" class="form-check-input" type="checkbox" v-model="checkList.totalAgree"/>
              </div>
              <label class="mb-0 ms-2" for="fieldTotalAgree"><b>I hereby certify that all of these items have been completed</b></label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start"  v-if="!ui.loading">
        <base-button title="Sign" action="secondary-default" type="submit" :disabled="!isAllChecked" @click-btn="$emit('submit')" />
        <base-button title="Cancel" action="secondary" @click-btn="$emit('close', null)" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: 'LoanCheckList',
  components: { BaseButton },
  data() {
    return {
      ui: {
        loading: false,
        btnLoading: false
      },
      checkList: {
        fullyExecuted: false,
        escrowContract: false,
        loanProgram: false,
        updateLendingPad: false,
        verifyBorrower: false,
        reviewCredit: false,
        VALoans: false,
        followingDocs: false,
        followingFees: false,
        runBot: false,
        totalAgree: false,
        isFHACondo: false,
        propertyProfile: false,
        taxBill: false
      }
    }
  },
  computed: {
    isAllChecked() {
      return Object.values(this.checkList).every(value => value === true)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-block {
    max-height: 700px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: initial;
    margin: 0;
  }
  .modal-body {
    height: calc(100% - 100px);
    padding: 30px;
    margin-left: 1rem;
    overflow-y: scroll;
    .r-2 {
      right: 1rem !important;
    }
  }
  .modal-header {
    position: relative;
  }
  .modal-footer {
    position: absolute;
  }
}

.check-block {
  display: flex;
  align-items: center;
  border: 1px solid rgba(231, 232, 232, 1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  padding: 12px 12px;
  width: fit-content;

  &.active {
    border: 1px solid rgba(1, 122, 255, 1);
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }

  label {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    margin: 0 0 0 12px;
    white-space: nowrap;
    cursor: pointer;
  }

  .form-check-input {
    height: 18px;
    max-width: 18px;
    margin: 0;
    cursor: pointer;

    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }
}
.form-check {
  padding: 0;
}
.form-check-input {
  height: 18px;
  max-width: 18px;
  margin: 0;
  cursor: pointer;

  &:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
}
ol {
  padding-left: 43px;
  margin-top: 10px;
  li {
    font-size: 15px;
  }
}
</style>
