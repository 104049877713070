<template>
  <div id="editOrigination" class="modal">
    <div class="modal-bg" @click="closeModal"></div>

    <ModalSpinner v-show="ui.loan.loading"></ModalSpinner>

    <div v-show="!ui.loan.loading && !lendingpad.showBlock" id="new-origination-form-modal-block" class="modal-block">
      <div class="modal-header">
        <h3>{{ isReferralLoan ? 'New Referral Loan' : 'New Origination' }}</h3>
      </div>
      <form class="modal-form" @submit="submitForm">
        <div id="modal-newLoan-scroll" class="modal-body">
          <div v-if="lendingpad.selectedBorrower === null" class="modal-body-container mt-0 pb-3">
            <div class="d-flex align-items-center justify-content-start cursor-pointer add-manually"
                 @click="selectBorrower">
              <img alt="Select borroweRecent from LendingPad r" src="@/assets/icons/bold-icon previous.svg"/>
              <div class="color-light-grey">Select From LendingPad</div>
            </div>
          </div>
          <div v-if="lendingpad.selectedBorrower" class="modal-body-container mt-4 borrower-selected cursor-pointer"
               @click="selectBorrower">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <div class="borrower-avatar">
                  <div>{{ lendingpad.borrower.imageName }}</div>
                </div>
                <div class="borrower-info">
                  <div class="borrower-from">From LendingPad</div>
                  <div class="">
                    <span class="borrower-name">{{ lendingpad.borrower.name }}</span>
                    <span class="borrower-conventional">{{ lendingpad.borrower.product }}</span>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <button class="btn btn-remove" @click="removeData">Remove</button>
              </div>
            </div>
          </div>

          <div class="modal-body-container mt-4">
            <div class="form-group full-width mb-2" v-if="isReferralLoan">
              <label>What state is the property located in?</label>
              <multiselect id="fieldStateLocation" v-model="loan.state"
                           :allow-empty="false"
                           :close-on-select="true"
                           :options="formData.states"
                           :searchable="true"
                           :show-labels="false"
                           @select="selectState"
                           class="full-width selected" label="label" track-by="value" placeholder="Select state">
              </multiselect>
            </div>
            <div class="form-group full-width mb-3" v-if="isReferralLoan">
              <label for="LO_field">Loan Officer of Record</label>
              <multiselect id="LO_field" v-model="loan.LOofRecord"
                           :allow-empty="false"
                           :class="{selected: loan.LOofRecord !== null}"
                           :close-on-select="true"
                           :options="loan.state.agents"
                           :searchable="true"
                           :show-labels="false"
                           track-by="value" label="label" class="full-width" placeholder="Select agent">
              </multiselect>
            </div>

            <div class="form-group full-width mb-2" v-if="isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_LOA') || isGranted('ROLE_LOAN_COORDINATOR')">
              <label>Agent</label>
              <multiselect id="fieldAgent" v-model="loan.author"
                           :allow-empty="false"
                           :close-on-select="true"
                           :options="formData.agents"
                           :searchable="true"
                           :show-labels="false"
                           class="full-width selected" label="label" track-by="value" placeholder="Select agent">
              </multiselect>
            </div>
            <div class="form-group full-width">
              <label v-tippy="{ placement : 'right',  arrow: true }"
                     content="Depends on workflow you will need to fill out different fields"
                     aria-label="fieldWorkflow">Select Workflow</label>
              <multiselect id="fieldWorkflow" v-model="ui.selectedWorkflow"
                           :allow-empty="false"
                           :close-on-select="true"
                           :options="formData.workflows"
                           :searchable="false"
                           :show-labels="false"
                           @select="toggleProcessors"
                           class="full-width selected" label="label" track-by="value" placeholder="Select workflow">
              </multiselect>
            </div>

            <div v-if="ui.selectedWorkflow.value === 'mlo'" class="alert alert-info mt-4">
              You are disclosing the file yourself. You can use the document
              checklist feature. Once you have collected stips, ran AUS and the
              borrower has e-signed, please submit to UW and come back here
              (edit Application) and assign to your processor.
            </div>
          </div>
          <div class="modal-body-container">
            <div class="form-group mb-3 full-width">
              <label aria-label="fieldWaivedStatus">Impounds/Escrows?</label>
              <multiselect id="fieldWaivedStatus" v-model="loan.waivedStatus"
                           :allow-empty="false"
                           :class="{ selected: loan.waivedStatus !== null }"
                           :close-on-select="true"
                           :options="formData.waivedStatuses"
                           :searchable="false"
                           :show-labels="false"
                           class="full-width" placeholder="Impounds/Escrows?">
              </multiselect>
            </div>

            <div class="form-group mb-3">
              <label for="fieldLenderType">Broker or Correspondent?</label>
              <multiselect id="fieldLenderType" v-model="loan.lenderType"
                           :allow-empty="false"
                           :class="{selected: loan.lenderType !== null}"
                           :close-on-select="true"
                           :options="formData.lenderTypes"
                           :searchable="false"
                           :show-labels="false"
                           placeholder="Lender Type" @select="checkLenderType">
              </multiselect>
            </div>

            <div class="form-group full-width mb-3">
              <label aria-label="fieldLender">Lender</label>
              <multiselect id="fieldLender"
                           v-model="loan.lender"
                           :allow-empty="false"
                           :class="{selected: loan.lender !== null}"
                           :close-on-select="true"
                           :options="formData.lenders"
                           :searchable="true"
                           :show-labels="false"
                           :disabled="loan.lenderType === 'Correspondent'"
                           @select="checkLenderTags"
                           class="full-width" label="label" track-by="value" placeholder="Select Lender">
              </multiselect>
            </div>


            <div class="mb-3"  v-if="ui.selectedWorkflow.value === 'processor'">
              <div class="form-group full-width">
                <label aria-label="fieldPreferredTitleCompany">Preferred Title Company</label>
                <multiselect id="fieldPreferredTitleCompany"
                             v-model="loan.preferredTitleCompany"
                             :allow-empty="false"
                             :class="{selected: loan.preferredTitleCompany !== null}"
                             :close-on-select="true"
                             :options="formData.companies"
                             :searchable="true"
                             :show-labels="false"
                             class="full-width" label="label" track-by="value" placeholder="Select Preferred Title Company">
                </multiselect>
              </div>
              <base-button title="Add a new title company" class="mt-3" action="secondary-default" @click-btn="ui.showCompanyFormModal = true" />
            </div>


            <div class="form-group mb-3" v-if="!isDisclosing">
              <label aria-label="fieldAus">AUS/DU/LP</label>
              <multiselect id="fieldAus" v-model="loan.aus"
                           :allow-empty="false"
                           :class="{ selected: loan.aus !== null }"
                           :close-on-select="true"
                           :options="formData.ausOptions"
                           :searchable="false"
                           :show-labels="false"
                           placeholder="AUS/DU/LP">
              </multiselect>
            </div>
          </div>
          <div class="modal-body-container">
            <div v-if="ui.selectedWorkflow.value !== 'mlo' && loan.lenderType === 'Broker'" class="row">
              <div class="form-group col-md-6">
                <div class="mb-3">
                  <label for="fieldAntiSteeringLowestRateAndFee">Anti Steering: Lowest Rate and Fee</label>
                  <input id="fieldAntiSteeringLowestRateAndFee" v-model="loan.antiSteeringLowestRateAndFee"
                         :class="{ 'input-filled': loan.antiSteeringLowestRateAndFee !== null }"
                         class="form-control" type="text"/>
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="mb-3">
                  <label for="fieldAntiSteeringHighestRateAndFee">Anti Steering: Highest Rate and Fee</label>
                  <input class="form-control" type="text"
                    id="fieldAntiSteeringHighestRateAndFee"
                    v-model="loan.antiSteeringHighestRateAndFee"
                    :class="{ 'input-filled': loan.antiSteeringHighestRateAndFee !== null }"/>
                </div>
              </div>
            </div>
            <div class="form-group full-width mb-3">
              <div class="form-group col-md-6 mb-3" v-if="isDisclosing">
                <label for="fieldLoanNumber">Loan Number</label>
                <input id="fieldLoanNumber" v-model.number="loan.number"
                      :class="{ 'input-filled': loan.number !== null }"
                      class="form-control" placeholder="Loan Number" type="number"/>
              </div>
              <div class="d-flex justify-content-start mt-3 mb-3" v-if="loan.lenderType === 'Correspondent'">
                <button class="btn btn-outline-dark px-4" type="button" @click="ui.showCorrPricerModal = true">
                  Select Pricing
                </button>
              </div>
              <div class="d-flex w-100 justify-content-between align-items-center mb-2">
                <label for="fieldOtherNotes" class="mb-0">Note</label>
              </div>
              <textarea id="fieldOtherNotes"
                        v-model="loan.feesForBorrower"
                        :class="{'input-filled': loan.feesForBorrower !== null}"
                        class="form-control"
                        placeholder="Put note in here to remind yourself exactly what fees you promised on this file"
                        rows="4"></textarea>
            </div>
            <div class="form-group full-width mb-3">
              <label for="fieldBorrowerMotivation">Borrower Motivation</label>
              <textarea
                id="fieldBorrowerMotivation"
                v-model="loan.otherNotes"
                :class="{ 'input-filled': loan.otherNotes !== null }"
                class="form-control"
                placeholder="Borrower motivation / What's the plan? / Anything else we should know?"
                rows="4"
              ></textarea>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="col-md-10 mb-4">
              <label aria-label="fieldProcessor">Processor</label>
              <multiselect id="fieldProcessor"
                           v-model="loan.processor"
                           :allow-empty="false"
                           :class="{selected: loan.processor !== null}"
                           :close-on-select="true"
                           :options="formData.processorOptions"
                           :searchable="true"
                           :show-labels="false"
                           class="full-width"
                           label="label"
                           placeholder="Select Processor"
                           track-by="value">
              </multiselect>
              <div class="processor-details" v-if="loan.processor && loan.processor.value !== $store.getters.getUserId">
                <img :src="agentPhoto(loan.processor.photoUrl)" alt="">
                <div>
                  <b>{{ loan.processor.name }}</b> <br>
                  {{ formatUSNumber(loan.processor.phone) }} / {{ loan.processor.email }}
                </div>
              </div>
            </div>
            <div class="form-group col-md-6 mb-4" v-if="ui.selectedWorkflow.value === 'processor'">
              <label aria-label="fieldVesting">Vesting</label>
              <multiselect id="fieldVesting"
                           v-model="loan.vesting"
                           :allow-empty="false"
                           :class="{selected: loan.vesting !== null}"
                           :close-on-select="true"
                           :options="formData.vestingOptions"
                           :searchable="false"
                           :show-labels="false">
              </multiselect>
            </div>
            <div class="form-group full-width mb-4" v-if="ui.selectedWorkflow.value === 'processor'">
              <label aria-label="fieldProcessor">
                How is title held?
                <img alt="preview"
                     v-tippy="{ placement : 'right',  arrow: true }"
                     content="This field is important to prevent issues at closing. If there is no change to title, copy and paste it from title profile."
                     src="@/assets/icons/icon-info.svg">
              </label>
              <textarea id="fieldLoanTitle"
                        v-model="loan.title"
                        :class="{'input-filled': loan.title !== null}"
                        class="form-control" rows="4">
              </textarea>
            </div>
            <div v-if="ui.selectedWorkflow.value === 'processor'">
              <label>Is there a non-borrowing spouse?</label>
              <div class="mb-3">
                <div class="d-flex">
                  <span :class="{ active: loan.isNonBorrowingSpouse }" class="check-btn"
                        @click="loan.isNonBorrowingSpouse = true">Yes</span>
                  <span :class="{ active: !loan.isNonBorrowingSpouse }" class="check-btn"
                        @click="loan.isNonBorrowingSpouse = false">No</span>
                </div>
              </div>
            </div>
            <div class="form-group full-width mb-3">
              <label>Stips</label>
              <file-field-new :files="stips.uploaded" :multiple="true"
                              field-id="new-origination-stips" @change="submitStips"/>
              <ul v-if="stips.received.length > 0" class="list-group mt-4">
                <li v-for="stip in stips.received" :key="stip.id"
                    class="list-group-item d-flex justify-content-between align-items-center">
                  {{ stip.clientFilename }}
                  <div class="list-group-item-icons">
                    <span
                      class="badge cursor-pointer"
                      @click="openFilePreview('stip', stip.id, stip.clientFilename)">
                      <img alt="preview" src="@/assets/icons/icon-preview.svg"/>
                    </span>
                    <span class="badge cursor-pointer" @click="deleteStip(stip.id)">
                      <img alt="delete" src="@/assets/icons/icon-delete.svg"/>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="modal-body-container mt-4">
            <div class="modal-body-container_title">Borrower</div>
            <div class="row mb-4">
              <div class="form-group col-md-6">
                <label for="fieldFirstName">First Name</label>
                <input id="fieldFirstName" v-model="loan.borrower.firstName"
                       :class="{ 'input-filled': loan.borrower.firstName !== null }"
                       class="form-control" placeholder="First Name" type="text"/>
              </div>
              <div class="form-group col-md-6">
                <label for="fieldLastName">Last Name</label>
                <input id="fieldLastName" v-model="loan.borrower.lastName"
                       :class="{ 'input-filled': loan.borrower.lastName !== null }"
                       class="form-control" placeholder="Last Name" type="text"/>
              </div>
            </div>
            <div class="row mb-4">
              <div class="form-group col-md-12 w-76">
                <label for="fieldEmail">Email</label>
                <input id="fieldEmail" v-model="loan.borrower.email"
                       :class="{ 'input-filled': loan.borrower.email !== null }"
                       class="form-control" placeholder="Email" type="text"/>
                <span class="pt-3">Use '/' or ',' to separate emails:
                    <i>example@mail.com / example@mail.com</i>
                  </span>
              </div>
            </div>
            <template>
              <div v-if="!isDisclosing" class="row mb-4">
                <div class="form-group col-md-6">
                  <label for="fieldCoFirstName">Co First Name</label>
                  <input id="fieldCoFirstName" v-model="loan.borrower.coFirstName"
                        :class="{ 'input-filled': loan.borrower.coFirstName !== null }"
                        class="form-control" placeholder="First Name (Optional)" type="text"/>
                </div>
                <div class="form-group col-md-6">
                  <label for="fieldCoLastName">Co Last Name</label>
                  <input id="fieldCoLastName" v-model="loan.borrower.coLastName"
                        :class="{ 'input-filled': loan.borrower.coLastName !== null }"
                        class="form-control" placeholder="Last Name (Optional)" type="text"/>
                </div>
              </div>
              <div v-if="!isDisclosing" class="row mb-4">
                <div class="form-group col-md-12 w-76">
                  <label for="fieldEmail">Co Email</label>
                  <input id="fieldEmail" v-model="loan.borrower.coEmail"
                         :class="{ 'input-filled': loan.borrower.coEmail !== null }"
                         class="form-control" placeholder="Email" type="text"/>
                  <span class="pt-3">Use '/' or ',' to separate emails:
                    <i>example@mail.com / example@mail.com</i>
                  </span>
                </div>
              </div>
              <div v-if="!isDisclosing" class="row mb-4">
                <div class="form-group col-md-6">
                  <label for="fieldCreditScore">Credit Score</label>
                  <input id="fieldCreditScore" v-model.number="loan.borrower.creditScore"
                        :class="{ 'input-filled': loan.borrower.creditScore !== null }"
                        class="form-control" placeholder="740" type="number"/>
                </div>
              </div>
              <div class="row mb-4" v-if="!isReferralLoan && !isDisclosing">
                <div class="form-group col-md-6">
                  <label aria-label="fieldPropertyState">Property State</label>
                  <multiselect id="fieldPropertyState"
                              v-model="loan.propertyState"
                              :allow-empty="false"
                              :class="{ selected: loan.propertyState !== null }"
                              :close-on-select="true"
                              :options="formData.states"
                              :searchable="true"
                              :show-labels="false"
                              placeholder="Select state">
                  </multiselect>
                </div>
                <div class="form-group col-md-6">
                  <label aria-label="fieldPropertyState">Property Zip</label>
                  <input id="fieldZipCode" type="text" class="form-control" placeholder="Zip Code"
                        v-model="loan.zip"
                        :class="{ 'input-filled': loan.zip !== null }"/>
                </div>
              </div>
            </template>
          </div>

          <div class="modal-body-container mb-0 pb-0 border-bottom-0">
            <template v-if="!isDisclosing">
              <div class="modal-body-container_title">Loan Details</div>
              <div>
                <label>Loan purpose</label>
                <div class="mb-3">
                  <div class="d-flex">
                    <span :class="{ active: loan.purpose === 'Refinance' }" class="check-btn"
                          @click="loan.purpose = 'Refinance'">Refinance</span>
                    <span :class="{ active: loan.purpose === 'Purchase' }" class="check-btn"
                          @click="loan.purpose = 'Purchase'">Purchase</span>
                    <span :class="{ active: loan.purpose === 'Home improvement' }" class="check-btn"
                          @click="loan.purpose = 'Home improvement'">Home improvement</span>
                  </div>
                </div>
              </div>

              <div class="mb-3 mt-3" v-if="loan.purpose === 'Purchase'">
                <button class="btn bg-green px-4" type="button" @click="ui.showAssignRealtorsModal = true">
                  Assign Realtors
                </button>

                <div class="form-group mt-3" v-if="loan.listingAgents && loan.listingAgents.length > 0">
                  <label for="">Listing Agents</label>
                </div>

                <div v-if="loan.listingAgents && loan.listingAgents.length > 0">
                  <div class="processor-details mt-1 justify-content-between" v-for="agent in loan.listingAgents" :key="'listing' + agent.id">
                    <div>
                      <b>{{ agent.fullName }} {{ agent.isTC ? "(TC)" : '' }}</b> <br>
                      {{ formatUSNumber(agent.phone) }} / {{ agent.email }}
                    </div>
                    <div>
                      <button class="btn btn-control" @click="removeListingAgent(agent.id)">
                        <img class="m-0" src="@/assets/icons/icon-remove.svg" alt="">
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group mt-3" v-if="loan.buyingAgents && loan.buyingAgents.length > 0">
                  <label for="">Buying Agents</label>
                </div>

                <div v-if="loan.buyingAgents && loan.buyingAgents.length > 0">
                  <div class="processor-details mt-1 justify-content-between" v-for="agent in loan.buyingAgents" :key="'buying' + agent.id">
                    <div>
                      <b>{{ agent.fullName }} {{ agent.isTC ? "(TC)" : '' }}</b> <br>
                      {{ formatUSNumber(agent.phone) }} / {{ agent.email }}
                    </div>
                    <div>
                      <button class="btn btn-control" @click="removeBuyingAgent(agent.id)">
                        <img class="m-0" src="@/assets/icons/icon-remove.svg" alt="">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- work1 -->
              <div v-if="loan.purpose === 'Purchase'" class="form-group full-width other-options mb-3">
                <div class="form-check">
                  <input id="isFirstTimeHomeBuyer" class="form-check-input" type="checkbox" v-model="loan.isFirstTimeHomeBuyer" />
                  <label class="form-check-label" for="isFirstTimeHomeBuyer">First Time Homebuyer</label>
                </div>
              </div>
              <div class="form-group full-width other-options mb-3">
                <div class="form-check">
                  <input id="addHOA" class="form-check-input" type="checkbox" v-model="ui.form.addHOA" />
                  <label class="form-check-label" for="addHOA">Add HOA Information</label>
                </div>
                <template v-if="ui.form.addHOA">
                  <input
                    v-model="loan.hoaName"
                    :class="{ 'input-filled': loan.hoaName !== null }"
                    class="form-control mb-3"
                    type="text"
                    placeholder="Name"
                  />
                  <input
                    v-model="loan.hoaPhone"
                    :class="{ 'input-filled': loan.hoaPhone !== null }"
                    class="form-control mb-3"
                    type="text"
                    v-mask="'(###) ###-####'"
                    placeholder="Phone Number"
                  />
                  <input
                    v-model="loan.hoaEmail"
                    :class="{ 'input-filled': loan.hoaEmail !== null }"
                    class="form-control mb-3"
                    type="email"
                    placeholder="Email"
                  />
                </template>
              </div>

              <div class="form-group full-width mb-3" v-if="loan.purpose === 'Refinance'">
                <label for="fieldPayingOffMortgagesNumber">
                  How many mortgages are we paying off on this property?
                </label>
                <input id="fieldPayingOffMortgagesNumber" v-model.number="loan.payingOffMortgagesNumber"
                      :class="{ 'input-filled': loan.payingOffMortgagesNumber !== null}"
                      class="form-control small-width" placeholder="1" type="number"/>
              </div>
              <div class="row mb-4">
                <div class="form-group col-md-6">
                  <label aria-label="fieldLoanProduct">Product</label>
                  <multiselect id="fieldLoanProduct" v-model="loan.productType"
                              :allow-empty="false"
                              :class="{ selected: loan.productType !== null }"
                              :close-on-select="true"
                              :options="formData.loanTypes"
                              :searchable="false"
                              :show-labels="false"
                              placeholder="Select product">
                  </multiselect>
                </div>
                <div class="form-group col-md-6">
                  <label for="fieldProductAndTerm">Product and Term</label>
                  <input id="fieldProductAndTerm" v-model="loan.productAndTerm"
                        :class="{ 'input-filled': loan.productAndTerm !== null }"
                        class="form-control" placeholder="30y fixed" type="text"/>
                </div>
              </div>

              <div v-if="ui.selectedWorkflow.value === 'processor' && loan.productType === 'VA'" class="row mb-4">
                <div class="form-group col-md-6">
                  <label aria-label="fieldVaType">VA Type</label>
                  <multiselect id="fieldVaType" v-model="loan.vaType"
                               :allow-empty="false"
                               :class="{ selected: loan.vaType !== null }"
                               :close-on-select="true"
                               :options="formData.vaTypes"
                               :searchable="false"
                               :show-labels="false"
                               placeholder="VA Type">
                  </multiselect>
                </div>
                <div class="form-group col-md-6">
                  <label aria-label="fieldVaUseType">VA Use Type</label>
                  <multiselect id="fieldVaUseType" v-model="loan.vaUseType"
                               :allow-empty="false"
                               :class="{ selected: loan.vaUseType !== null }"
                               :close-on-select="true"
                               :options="formData.vaUseTypes"
                               :searchable="false"
                               :show-labels="false"
                               placeholder="VA Use Type">
                  </multiselect>
                </div>
              </div>

              <div v-if="loan.productType === 'VA'" class="form-group full-width mb-3">
                <label aria-label="fieldVaExempt">VA EXEMPT?</label>
                <multiselect id="fieldVaExempt" v-model="loan.vaExempt"
                            :allow-empty="false"
                            :class="{ selected: loan.vaExempt !== null }"
                            :close-on-select="true"
                            :options="formData.vaExemptOptions"
                            :searchable="false"
                            :show-labels="false"
                            label="label" track-by="value" placeholder="VA Exempt">
                </multiselect>
                <div class="form-text">
                  (borrower >10% disabled) - THIS IS IMPORTANT IN PRODUCT
                  SELECTION
                </div>
              </div>

              <div class="row mb-3">
                <div class="form-group col-md-6">
                  <label for="fieldLoanAmount">Loan Amount</label>
                  <div class="input-with-icon mb-2">
                    <span class="icon">$</span>
                    <input id="fieldLoanAmount" v-model.number="loan.amount"
                          :class="{ 'input-filled': loan.amount !== null }"
                          class="form-control" step="0.01" type="number"/>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="fieldLoanAmount">Property value</label>
                  <div class="input-with-icon mb-2">
                    <span class="icon">$</span>
                    <input id="fieldLoanAmount" v-model.number="loan.propertyValue"
                           :class="{ 'input-filled': loan.propertyValue !== null }"
                           class="form-control" step="0.01" type="number"/>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="fieldLoanNumber">Loan Number</label>
                  <input id="fieldLoanNumber" v-model.number="loan.number"
                        :class="{ 'input-filled': loan.number !== null }"
                        class="form-control" :placeholder="loanNumberPlaceHolder" type="number"/>
                </div>
              </div>
              <div class="row mb-3">
                <div class="form-group col-md-6">
                  <label aria-label="fieldOccupancy">Occupancy</label>
                  <multiselect id="fieldOccupancy" v-model="loan.occupancy"
                              :allow-empty="false"
                              :class="{ selected: loan.occupancy !== null }"
                              :close-on-select="true"
                              :options="formData.occupancies"
                              :searchable="false"
                              :show-labels="false"
                              placeholder="Select occupancy">
                  </multiselect>
                </div>
                <div class="form-group col-md-6">
                  <label for="fieldNumberOfUnits">Number Of Units</label>
                  <input id="fieldNumberOfUnits" v-model.number="loan.numberOfUnits"
                        :class="{ 'input-filled': loan.numberOfUnits !== null }"
                        class="form-control" placeholder="1" type="number" min="1" max="4"/>
                </div>
              </div>
              <div class="row mb-3">
                <div class="form-group col-md-6">
                  <label aria-label="fieldPropertyType">Property Type</label>
                  <multiselect id="fieldPropertyType" v-model="loan.propertyType"
                              :allow-empty="false"
                              :class="{ selected: loan.propertyType !== null }"
                              :close-on-select="true"
                              :options="formData.propertyTypes"
                              :searchable="false"
                              :show-labels="false"
                              placeholder="Select property type">
                  </multiselect>
                </div>
                <div class="form-group col-md-6" v-if="showAttachmentType">
                  <label aria-label="fieldPropertyType">Attachment Type</label>
                  <multiselect id="fieldAttachmentType" v-model="loan.attachmentType"
                              :allow-empty="false"
                              :class="{ selected: loan.attachmentType !== null }"
                              :close-on-select="true"
                              :options="formData.attachmentTypes"
                              :searchable="false"
                              :show-labels="false"
                              placeholder="Select property type">
                  </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="form-group mb-3">
                  <label for="fieldRate">Rate</label>
                  <div class="input-with-icon mb-2">
                    <span class="icon">%</span>
                    <input id="fieldRate" v-model.number="loan.rate"
                          :class="{ 'input-filled': loan.rate !== null }"
                          class="form-control" placeholder="2.875" step=".001" type="number"/>
                  </div>
                </div>
              </div>

              <div class="row mb-3" v-if="loan.purpose === 'Purchase'">
                <div class="form-group col-md-6">
                  <label for="fieldRate">Close of Escrow Date</label>
                  <input id="fieldRate" v-model="loan.closeOfEscrowDate"
                         :class="{ 'input-filled': loan.closeOfEscrowDate !== null }"
                         class="form-control"
                         type="date"/>
                </div>
                <div class="form-group col-md-6">
                  <label for="fieldRate">Appraisal Contingency Date</label>
                  <input id="fieldRate" v-model="loan.appraisalContingencyDate"
                         :class="{ 'input-filled': loan.appraisalContingencyDate !== null }"
                         class="form-control"
                         type="date"/>
                </div>
              </div>

              <div class="row mb-3" v-if="loan.purpose === 'Purchase'">
                <div class="form-group col-md-6">
                  <label for="fieldRate">Approval Contingency Date</label>
                  <input id="fieldRate" v-model="loan.approvalContingencyDate"
                         :class="{ 'input-filled': loan.approvalContingencyDate !== null }"
                         class="form-control"
                         type="date"/>
                </div>
                <div class="form-group col-md-6">
                  <label for="fieldRate">Inspection Contingency Date</label>
                  <input id="fieldRate" v-model="loan.inspectionContingencyDate"
                         :class="{ 'input-filled': loan.inspectionContingencyDate !== null }"
                         class="form-control"
                         type="date"/>
                </div>
              </div>

              <div class="row">
                <div class="form-group mb-3" v-if="ui.selectedWorkflow.value === 'processor'">
                  <label aria-label="fieldLoanProduct">Are we locking or floating?</label>
                  <multiselect id="fieldRateType" v-model="loan.discloseRateType"
                               :allow-empty="false"
                               :class="{ selected: loan.discloseRateType !== null }"
                               :close-on-select="true"
                               :options="formData.discloseRateTypes"
                               :searchable="false"
                               :show-labels="false" label="label" track-by="value">
                  </multiselect>
                </div>
              </div>

              <div class="row">
                <div class="form-group mb-3">
                  <label for="fieldLendingPadNumber">LendingPad Number</label>
                  <input id="fieldLendingPadNumber" v-model="loan.lendingpadNumber"
                        :class="{ 'input-filled': loan.lendingpadNumber !== null }"
                        class="form-control" placeholder="LendingPad Number (Optional)" type="text"/>
                </div>
              </div>

              <div class="row mb-4" v-if="ui.lenderOfReverse">
                <div class="form-group">
                  <label aria-label="fieldReverseMortgageType">Reverse Mortgage Type</label>
                  <multiselect id="fieldReverseMortgageType" v-model="loan.reverseMortgageType"
                              :options="formData.mortgageTypeOptions"
                              :class="{ selected: loan.reverseMortgageType !== null }"
                              :show-labels="false"
                              :searchable="false"
                              :allow-empty="false"
                              :close-on-select="true"
                              class="full-width" placeholder="Select Mortgage Type">
                  </multiselect>
                </div>

                <div class="form-group">
                  <label aria-label="fieldReverseMortgagePurpose">Reverse Mortgage Purpose</label>
                  <multiselect id="fieldReverseMortgagePurpose" v-model="loan.reverseMortgagePurpose"
                              :options="formData.mortgagePurposeOptions"
                              :class="{ selected: loan.reverseMortgagePurpose !== null }"
                              :show-labels="false"
                              :searchable="false"
                              class="full-width"
                              placeholder="Select Mortgage Purpose">
                  </multiselect>
                </div>
              </div>

              <div class="row">
                <div class="form-group mb-3">
                  <label for="fieldLienPosition">Lien Position</label>
                  <multiselect id="fieldLienPosition" v-model="loan.lienPosition"
                              :allow-empty="false"
                              :class="{ selected: loan.lienPosition !== null }"
                              :close-on-select="true"
                              :options="formData.lienPositionOptions"
                              :searchable="false"
                              :show-labels="false"
                              placeholder="Select Lien Position">
                  </multiselect>
                </div>
                <div class="form-group col-6" v-if="ui.lenderHasTagSecond">
                  <label>Is this a second mortgage</label>
                  <div class="mb-3">
                    <div class="d-flex">
                      <span :class="{ active: loan.lienPosition === 'Subordinate' }" class="check-btn small"
                            @click="loan.lienPosition = 'Subordinate'">YES</span>
                      <span :class="{ active: loan.lienPosition === 'First' }" class="check-btn small"
                            @click="loan.lienPosition = 'First'">NO</span>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="ui.isHighCostMortgage">
                <label>Is this a High-Cost Mortgage?</label>
                <div class="mb-3">
                  <div class="d-flex">
                    <span :class="{ active: loan.isHighCostMortgage === true }" class="check-btn"
                          @click="loan.isHighCostMortgage = true">Yes</span>
                    <span :class="{ active: loan.isHighCostMortgage === false }" class="check-btn"
                          @click="loan.isHighCostMortgage = false">No</span>
                  </div>
                </div>
              </div>

              <div class="form-group mb-3">
                <label aria-label="fieldMiStatus">Is there MI?</label>
                <multiselect id="fieldMiStatus" v-model="loan.miStatus"
                            :allow-empty="false"
                            :class="{ selected: loan.miStatus !== null }"
                            :close-on-select="true"
                            :options="formData.miStatuses"
                            :searchable="false"
                            :show-labels="false"
                            placeholder="MI Status">
                </multiselect>
              </div>

              <div v-if="loan.lenderType === 'Broker'" class="form-group mb-3">
                <label aria-label="fieldCompensationType">Compensation Type</label>
                <multiselect id="fieldCompensationType" v-model="loan.compensationType"
                            :allow-empty="false"
                            :class="{ selected: loan.compensationType !== null }"
                            :close-on-select="true"
                            :options="formData.compensationTypeOptions"
                            :searchable="false"
                            :show-labels="false"
                            placeholder="Compensation Type">
                </multiselect>
              </div>

              <div v-if="loan.lenderType === 'Broker'" class="form-group mb-3 full-width">
                <label aria-label="fieldWaivingUnderwriting">
                  Are we waiving Underwriting? Is the fee built into the pricing?
                </label>
                <multiselect id="fieldWaivingUnderwriting" v-model="loan.waivingUnderwriting"
                            :allow-empty="false"
                            :class="{ selected: loan.waivingUnderwriting !== null }"
                            :close-on-select="true"
                            :options="formData.waivingUnderwritingOptions"
                            :searchable="false"
                            :show-labels="false"
                            label="label" track-by="value">
                </multiselect>
              </div>

              <div v-if="loan.waivedStatus === 'YES/Included in Payment' && ui.selectedWorkflow.value !== 'mlo'"
                  class="form-group full-width mb-3">
                <label for="fieldTaxesMonthsToCollect">How many months of taxes to collect?</label>
                <input id="fieldTaxesMonthsToCollect" v-model.number="loan.taxesMonthsToCollect"
                      :class="{ 'input-filled': loan.taxesMonthsToCollect !== null }"
                      class="form-control small-width" placeholder="1" type="number"/>
              </div>

              <div v-if="loan.waivedStatus === 'YES/Included in Payment'" class="form-group full-width mb-3">
                <label for="fieldInsuranceMonthsToCollect">How many months of insurance to collect?</label>
                <input id="fieldInsuranceMonthsToCollect" v-model.number="loan.insuranceMonthsToCollect"
                      :class="{ 'input-filled': loan.insuranceMonthsToCollect !== null }"
                      class="form-control small-width" placeholder="1" type="number"/>
              </div>

              <div v-if="ui.selectedWorkflow.value !== 'mlo'" class="form-group full-width mb-3">
                <label for="fieldDaysOfInterest">How many days of interest do we need to collect?</label>
                <input id="fieldDaysOfInterest" v-model.number="loan.daysOfInterest"
                      :class="{ 'input-filled': loan.daysOfInterest !== null }"
                      class="form-control small-width" placeholder="1" type="number"/>
              </div>

              <div class="form-group full-width mb-3" v-if="loan.purpose !== 'Purchase'">
                <label for="fieldCashOutAmount">Cash Out Amount (Leave blank if Rate/Term)</label>
                <div class="input-with-icon form-group mb-2">
                  <span class="icon">$</span>
                  <input id="fieldCashOutAmount" v-model.number="loan.cashoutAmount"
                        :class="{ 'input-filled': loan.cashoutAmount !== null }"
                        class="form-control" placeholder="15000" step="0.01" type="number"/>
                </div>
              </div>

              <div class="form-group mb-3" v-if="loan.purpose !== 'Purchase'">
                <label for="fieldShortToClose">Short to Close?</label>
                <input id="fieldShortToClose" v-model="loan.shortToClose"
                      :class="{ 'input-filled': loan.shortToClose !== null }"
                      class="form-control" type="text"/>
              </div>
            </template>

            <div class="form-group mb-3" v-if="ui.selectedWorkflow.value === 'processor'">
              <label for="fieldEstimatedClosingDate">Estimated Closing Date</label>
              <input id="fieldEstimatedClosingDate" v-model="loan.estimatedClosingDate"
                     :class="{ 'input-filled': loan.estimatedClosingDate !== null }"
                     class="form-control" type="date"/>
            </div>

            <div class="form-group full-width mb-3">
              <label aria-label="fieldAppraisalPayer">Who Pays for Appraisal?</label>
              <multiselect id="fieldAppraisalPayer" v-model="loan.appraisalPayer"
                           :allow-empty="false"
                           :class="{ selected: loan.appraisalPayer !== null }"
                           :close-on-select="true"
                           :options="formData.appraisalPayerOptions"
                           :searchable="false"
                           :show-labels="false"
                           class="full-width" placeholder="Who Pays for Appraisal?">
              </multiselect>
            </div>

            <div class="form-group full-width mb-3">
              <label for="fieldAppraisalNote">Appraisal Note</label>
              <input id="fieldAppraisalNote" v-model="loan.appraisalNote"
                     :class="{ 'input-filled': loan.appraisalNote !== null }"
                     class="form-control" maxlength="255" placeholder="Appraisal Note" type="text"/>
            </div>

            <div class="form-group full-width mb-3">
              <label for="source">Where did the deal come from?</label>
              <multiselect id="source" v-model="loan.source"
                           :allow-empty="false"
                           :class="{ selected: loan.source !== null }"
                           :close-on-select="true"
                           :options="formData.sourceOptions"
                           :searchable="false"
                           :show-labels="false"
                           class="full-width">
              </multiselect>
            </div>
            <div class="form-group full-width mb-3" v-if="loan.source === 'Other'">
              <label for="fieldBorrowerMotivation">Please Describe “Other”</label>
              <textarea
                id="fieldBorrowerMotivation"
                v-model="loan.sourceOtherDescription"
                :class="{ 'input-filled': loan.sourceOtherDescription !== null }"
                class="form-control"
                rows="4">
              </textarea>
            </div>

            <div class="form-group full-width mb-3" v-if="!isReferralLoan">
              <label for="LO">Loan Officer of Record</label>
              <multiselect id="LO" v-model="loan.LOofRecord"
                           :allow-empty="false"
                           :class="{selected: loan.LOofRecord !== null}"
                           :close-on-select="true"
                           :options="formData.LOofRecordOptions"
                           :searchable="true"
                           :show-labels="false"
                           class="full-width" track-by="value" label="label" placeholder="Select agent">
              </multiselect>
            </div>

            <div class="form-group mt-4 mb-3 other-options">
              <label>Other Options</label>
              <div :class="{active: loan.isHeroLoan === true}" class="form-check">
                <input id="fieldIsHeroLoan" v-model="loan.isHeroLoan" class="form-check-input" type="checkbox">
                <label class="form-check-label" for="fieldIsHeroLoan">HERO Loan</label>
              </div>
              <div :class="{active: loan.isSubordination === true}" class="form-check">
                <input id="fieldIsSubordination" v-model="loan.isSubordination" class="form-check-input"
                       type="checkbox">
                <label class="form-check-label" for="fieldIsSubordination">Subordination</label>
              </div>
              <div :class="{active: loan.isSolarPanels === true}" class="form-check">
                <input id="fieldIsSolarPanels" v-model="loan.isSolarPanels" class="form-check-input" type="checkbox">
                <label class="form-check-label" for="fieldIsSolarPanels">Solar Panels</label>
              </div>
              <div :class="{active: loan.eVerifyIncome === true}" class="form-check">
                <input id="fieldEVerifyIncome" v-model="loan.eVerifyIncome" class="form-check-input" type="checkbox">
                <label class="form-check-label" for="fieldEVerifyIncome">E-Verify Income</label>
              </div>
            </div>

            <!-- NOTE: For Credit login username and password -->
            <template v-if="ui.selectedWorkflow.value === 'processor'">
                <div class="form-group full-width other-options mb-3">
                    <label for="creditlogin">
                        Credit login
                        <img alt="preview"
                             v-tippy="{ placement : 'right',  arrow: true }"
                             content="Needed so SA can re issue credit at the lender"
                             src="@/assets/icons/icon-info.svg"
                        >
                    </label>
                    <div class="form-check">
                        <input id="alreadyhave-details" class="form-check-input" type="checkbox" v-model="loan.alreadyHasCreditLogin" />
                        <label class="form-check-label" for="alreadyhave-details">Assistant already has my credit credentials.</label>
                    </div>
                    <template v-if="!loan.alreadyHasCreditLogin">
                        <input
                            v-model="loan.creditLoginUserName"
                            :class="{ 'input-filled': loan.creditLoginUserName !== null }"
                            class="form-control mb-3"
                            type="text"
                            placeholder="Username"
                        />
                        <div class="input-with-icon icon-right">
                            <input
                                v-model="loan.creditLoginPassword"
                                :class="{ 'input-filled': loan.creditLoginPassword !== null }"
                                class="form-control"
                                :type="ui.showPasswordForClientLogin ? 'text' : 'password'"
                                placeholder="Password"
                            />
                            <img class="icon" alt="preview" src="@/assets/icons/icon-Show password.svg" @click="ui.showPasswordForClientLogin = !ui.showPasswordForClientLogin" />
                        </div>
                    </template>
                </div>
            </template>

            <div class="modal-body-container d-flex understand pb-0 pt-2">
              <div class="pt-1">
                <input id="fieldAccept" v-model="loan.accept" class="form-check" required type="checkbox"/>
              </div>
              <div>
                <label for="fieldAccept">
                  I understand it is my responsibility to be aware of lenders’
                  individual Early Payoff policies and it is my responsibility
                  to actively avoid early payoffs and I understand that I will
                  be required to return 100% of the commission I earned on this
                  funding if this loan is paid off Early.
                </label>
              </div>
            </div>

            <ValidationErrorsList :error="ui.form.error" :errors="ui.form.errors" :isError="ui.form.isError"/>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-start">
          <base-button title="Submit Origination" action="secondary-default"
                       @click-btn="submitLoanForm" :loading="ui.form.loading" :disabled="!loan.accept" />
          <base-button title="Discard" type="reset" action="secondary" @click-btn="closeModal" class="ms-2" />

          <div :class="{ 'fade show': ui.form.isSuccess }" aria-atomic="true" aria-live="assertive"
               class="toast d-flex align-items-center text-white bg-success border-0"
               role="alert">
            <div class="toast-body">Information successfully saved!</div>
            <button aria-label="Close" class="btn-close btn-close-white ms-auto me-2"
                    data-dismiss="toast" type="button" @click="ui.form.isSuccess = false">
            </button>
          </div>
        </div>
      </form>
    </div>

    <div v-show="!ui.loan.loading && lendingpad.showBlock" class="modal-block select-from-lendingpad">
      <div class="modal-header">
        <h3>New Origination</h3>
        <button class="btn btn-link" @click="lendingpad.confirmBlock = true">Add Manually</button>
      </div>
      <div class="modal-search-block" v-if="!lendingpad.confirmBlock">
        <div class="search-wrapper">
          <img alt="Icon search" src="@/assets/icons/icon-search.svg"/>
          <input v-model="filter.query" aria-label="Search" placeholder="Search by Borrower Name" type="text"/>
        </div>
      </div>
      <div class="modal-body pt-2">
        <div class="modal-body-container border-0 mt-2">
          <div v-if="lendingpad.confirmBlock" class="p-4 confirm-alert alert alert-warning">
            <h6>
              Are you sure you want to add this manually? It will most likely
              cause issues when you create a funding request.
            </h6>
            <div class="mt-3">
              <button class="btn bg-green" type="button" @click="confirmAddManually(true)">Yes</button>
              <button class="btn btn-cancel mx-2" type="button" @click="confirmAddManually(false)">
                Nevermind I will select from the LendingPad list
              </button>
            </div>
          </div>
          <div v-else>
            <h3 class="modal-body-container_title color-grey mb-3">
              Recent From LendingPad
            </h3>
            <div v-if="lendingpad.loading" class="text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <div class="modal-alert modal-alert_advice text-center" v-else-if="tableBorrowerSearch.length === 0">
              No Records Found
            </div>
            <div v-else class="borrowers-list">
              <table class="table table-hover">
                <tbody>
                <tr v-for="borrower in tableBorrowerSearch" :key="borrower.id" @click="selectBorrowerById(borrower.id)">
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="borrower-avatar grey">
                        <div>{{ borrower.imageName }}</div>
                      </div>
                      <div class="ms-3">
                        <div class="borrower-name">{{ borrower.name }}</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="conventional d-flex align-items-center">
                      {{ convertToCurrency(borrower.amount) }} •
                      {{ borrower.product }} {{ borrower.rate }}
                    </div>
                  </td>
                  <td>
                    <div class="conventional d-flex align-items-center">{{ borrower.updatedAt | timeAgo }}</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center" v-if="ui.loadingPaging">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="fade">
      <corr-pricer-modal v-if="ui.showCorrPricerModal"
                         :borrower-full-name="borrowerFullName(this.loan)"
                         :loan-amount="loan.amount"
                         :credit-score="loan.borrower.creditScore"
                         :state="loan.propertyState"
                         :property-use="loan.occupancy"
                         :purpose="loan.purpose"
                         :property-value="loan.propertyValue"
                         :property-zip="loan.propertyZip"
                         :rate="Number.parseFloat(loan.rate)"
                         @submit-proposition="selectCorrPricing"
                         @close="ui.showCorrPricerModal = false"/>
    </transition>
    <transition name="fade">
      <NewRealtorModal v-if="ui.realtorModal"
                       @close="closeRealtorModal">
      </NewRealtorModal>
    </transition>
    <transition name="fade">
      <LoanCheckList @close="ui.showChecklistForm = false" v-if="ui.showChecklistForm" @submit="submitChecklist" />
    </transition>
    <transition name="fade">
      <NewListingAgentModal v-if="ui.listingAgentModal"
                       @close="closeListingAgentModal">
      </NewListingAgentModal>
    </transition>
    <transition name="fade">
      <NewBuyingAgentModal v-if="ui.buyingAgentModal" @close="closeBuyingAgentModal">

      </NewBuyingAgentModal>
    </transition>
    <transition name="fade">
      <assign-realtors-modal :listing-agents="formData.listingAgents" :buying-agents="formData.buyingAgents" :listing-agent="loan.listingAgents"
                             v-if="ui.showAssignRealtorsModal" @close="closeAssignRealtorsModal" :buying-agent="loan.buyingAgents" />
    </transition>

    <transition name="slide-fade">
      <CompanyForm v-if="ui.showCompanyFormModal" @close="ui.showCompanyFormModal = false" @addedCompany="addedCompany" />
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import Multiselect from "vue-multiselect";
import ValidationErrorsList from "../../components/form/ValidationErrorsList";
import ModalSpinner from "../../components/modal/ModalSpinner";
import PerfectScrollbar from "perfect-scrollbar";
import FileFieldNew from "../../components/FileFieldNew";
import CorrPricerModal from "./modals/CorrPricerModal";
import BaseButton from '../../components/BaseButton.vue';
import NewRealtorModal from "@/views/loans/modals/NewRealtorModal.vue";
import NewListingAgentModal from "./modals/NewLIstingAgentModal.vue";
import NewBuyingAgentModal from "./modals/NewBuyingAgentModal.vue";
import AssignRealtorsModal from "./modals/AssignRealtorsModal.vue";
import LoanCheckList from "./modals/LoanCheckList.vue";
import CompanyForm from "../escrowCompanies/CompanyForm.vue";

export default {
  name: "NewOriginationModal",
  components: {
    LoanCheckList,
    AssignRealtorsModal,
    NewBuyingAgentModal,
    NewListingAgentModal,
    CorrPricerModal, FileFieldNew, ModalSpinner, ValidationErrorsList, Multiselect, BaseButton, NewRealtorModal, CompanyForm},
  props: {
    loanId: Number,
    isReferralLoan: Boolean
  },
  data() {
    return {
      stips: {
        received: [],
        uploaded: []
      },
      formData: {
        vaTypes: [
          'None',
          'VA Cash Out Type I',
          'VA Cash Out Type II',
          'VA IRLL',
        ],
        vaUseTypes: [
          'Select One',
          'First Use',
          'Subsequent Use'
        ],
        loanTypes: [
          "Conventional",
          "Conventional Hi-Bal",
          "FHA",
          "VA",
          "Non-QM",
          "JUMBO",
          "USDA/FSA/FHS",
          "HELOC",
          "Fixed Lien",
          "DSCR/business purpose"
        ],
        discloseRateTypes: [
          {value: 'lock', label: 'Lock immediately'},
          {value: 'float', label: 'Float'},
        ],
        workflows: [
          {value: 'mlo', label: 'I am disclosing and collecting stips (LO disclose)'},
          {value: 'processor', label: 'Submission Assistant'}
        ],
        lenders: [], // [{value: 1, label: "UWM"}, ...]
        companies: [],
        realtors: [
          {id: 'new_realtor', fullName: '+ New Realtor'},
        ],
        listingAgents: [
          {id: 'new_agent', fullName: '+ New Agent'},
        ],
        buyingAgents: [
          {id: 'new_agent', fullName: '+ New Agent'},
        ],
        mortgageTypeOptions: ['HECM-Standard', 'HECM-Saver', 'Proprietary/Other'],
        mortgagePurposeOptions: ['Home Purchase', 'Other'],
        lienPositionOptions: ['First', 'Second', 'Subordinate', 'Not Secured by Lien'],
        processors: [], // [{value: 1, label: "John Doe"}, ...]
        enhancedProcessors: [], // [{value: 1, label: "John Doe"}, ...]
        processorOptions: [],
        vestingOptions: ['Community Property', 'Joint Tenants', 'Sole'],
        LOofRecordOptions: [],
        states: [],
        agents: [],
        occupancies: [
          'Primary Residence',
          'Secondary Residence',
          'Investment Property',
          'Commercial',
          'Other'
        ],
        propertyTypes: [
          'Single Family Residence',
          '2-4 Unit Dwelling',
          'Condominium',
          'Modular',
          'Planned Unit Development',
          'Site Condo',
          'Manufactured Multi Wide',
          'Manufactured Single Wide',
          'Manufactured Condo Single Wide',
          'Manufactured PUD Single Wide',
          'Manufactured Condo Multi Wide',
          'Manufactured PUD Multi Wide',
          'Commercial Other'
        ],
        attachmentTypes: [
          'Attached',
          'Detached',
        ],
        miStatuses: ['No', 'Yes, Lender Paid', 'Yes, Borrower Paid', 'Yes, FHA', 'Yes, Other'],
        lenderTypes: ['Broker', 'Correspondent'],
        waivedStatuses: ['Waived/Not Included in Payment', 'YES/Included in Payment', 'Yes, impound taxes only', 'Yes, impound insurance only'],
        appraisalPayerOptions: [
          "Borrower is paying",
          "Clear Mortgage paid and reimbursed",
          "Clear Mortgage paid and its PROMO",
          "Appraisal Waived",
          "Unsure still",
          "Hold appraisal for now"
        ],
        sourceOptions: [],
        ausOptions: ["DU", "LP", "Doesn't matter", "N/A"],
        vaExemptOptions: [
          {label: "Exempt", value: true},
          {label: "Not Exempt (Financed)", value: false}
        ],
        compensationTypeOptions: ["Borrower Paid", "Lender Paid"],
        waivingUnderwritingOptions: [
          {label: "Yes", value: true},
          {label: "No", value: false}
        ]
      },
      ui: {
        loan: {loading: false, isError: false},
        form: {
          loading: false,
          isError: false,
          isSuccess: false,
          error: "",
          errors: [],
          addHOA: false,
        },
        selectedWorkflow: {
          value: "mlo",
          label: "I am disclosing and collecting stips (LO disclose)"
        },
        realtorModal: false,
        listingAgentModal: false,
        buyingAgentModal: false,
        ps: null,
        psLender: null,
        lenderOfReverse: false,
        lenderHasTagSecond: false,
        isSecondMortgage: false,
        isHighCostMortgage: false,
        loadingPaging: false,
        showCorrPricerModal: false,
        showPasswordForClientLogin: false,
        showAssignRealtorsModal: false,
        loanCheckListIsReviewed: false,
        showChecklistForm: false,
        showCompanyFormModal: false
      },
      lendingpad: {
        loading: false,
        notfound: false,
        showSelect: false,
        showBlock: false,
        confirmBlock: false,
        borrowers: [],
        selectedBorrower: null,
        loadBorrower: false,
        borrower: {
          imageName: "NA"
        }
      },
      statusNotification: {
        msg: null,
        status: null,
        delay: 0
      },
      filter: {
        query: ""
      },
      paging: {
        offset: 0,
        limit: 50,
        total: 0
      },
    };
  },
  methods: {
    addedCompany(data) {
      this.formData.companies.push(data)
      this.loan.preferredTitleCompany = data
    },
    selectCorrPricing(val, formData) {
      let note = '';
      let isCredit = val.option.final_price < 0
      let finalPrice = Math.abs(val.option.final_price)

      note += `You are offering a ${val.option.term}-year fixed, for ${val.optionIdx}% with a `
      if (isCredit) {
        note += `(${this.convertToCurrency(finalPrice)}) credit.\n\n`
      } else {
        note += `${this.convertToCurrency(finalPrice)} discount fee.\n\n`
      }

      note += 'Origination:\n'
      note += 'Underwriting Fee: $1,395.00\n'
      if (isCredit) {
        note += `Lender Credit: (${this.convertToCurrency(finalPrice)})\n`
      } else {
        note += `Discount Fee: ${this.convertToCurrency(finalPrice)}\n`
      }

      let adjustedOriginationCharge = val.option.final_price + 1395;
      let adjustedOriginationChargeFormatted = this.convertToCurrency(Math.abs(adjustedOriginationCharge));
      if (isCredit) {
        if (adjustedOriginationCharge < 0) {
          note += `Adjusted Origination Charge: (${adjustedOriginationChargeFormatted})\n\n`
        } else {
          note += `Adjusted Origination Charge: ${adjustedOriginationChargeFormatted}\n\n`
        }
      } else {
        note += `Adjusted Origination Charge: ${this.convertToCurrency(finalPrice + 1395)}\n\n`
      }

      if (isCredit) {
        note += 'The fees will look like this on the Loan Estimate:\n\n'
      }

      note += 'Section A:\n'
      if (!isCredit) {
        let formattedFinalPrice = this.convertToCurrency(finalPrice)
        let priceAsNumber = Number.parseFloat((finalPrice / formData.loanAmount) * 100).toFixed(5)
        note += `Correspondent Discount Fee: ${formattedFinalPrice} (${priceAsNumber})\n`
      }
      note += 'Underwriting Fee: $1,395.00\n'
      note += 'Wire Fee: $47.00\n'
      if (isCredit) {
        note += `Correspondent Lender Credit: ${this.convertToCurrency(finalPrice)}\n`
      }

      note += '\n\n'

      note += 'Section B:\n'
      note += 'Flood Certificate: $8\n'
      note += 'Tax Service: $85\n'

      if (this.isGranted('ROLE_SD_COMP_PLAN')) {
        note += 'Third Party Processing: $1990\n'
      }

      if (this.showAttachmentType) {
        note += 'HOA Fee: $250.00\n'
      }

      if (formData.loanType === 'VA') {
        note += 'Pest Inspection: $300.00\n'
        if (formData.state === 'TX') {
          note += 'Survey Fee: $600.00\n'
        }
      }

      // if (val.option.fundingFee > 0) {
      //   note += `Funding fee: ${this.convertToCurrency(val.option.fundingFee)}\n`
      // }

      note += '\n'

      note += 'Section C + D:\n'
      note += 'Third Party fees\n\n'

      this.loan.propertyState = formData.state
      this.loan.zip = formData.zip
      this.loan.county = formData.county
      this.loan.purpose = formData.loanPurpose
      this.loan.propertyType = formData.propertyType
      this.loan.propertyValue = formData.propertyValue
      this.loan.numberOfUnits = formData.numberOfUnits
      this.loan.attachmentType = formData.propertyAttachmentType
      this.loan.occupancy = formData.propertyUse
      this.loan.amount = formData.loanAmount
      this.loan.feesForBorrower = note
      this.loan.rate = val.optionIdx;
      this.loan.productAndTerm = val.option.term
      this.ui.showCorrPricerModal = false;
    },
    submitChecklist() {
      this.ui.showChecklistForm = false

      this.submitForm();
    },
    submitLoanForm() {
      if (this.isGranted('ROLE_SHOW_LOAN_CHECKLIST')) {
        this.ui.showChecklistForm = true
      } else {
        this.submitForm();
      }
    },
    submitForm(e) {
      if (e) {
        e.preventDefault();
      }

      this.ui.form.errors = [];
      this.ui.form.error = "";
      this.ui.form.isError = false;

      if (this.ui.selectedWorkflow.value === 'processor' && !confirm('Remember to assign LOA in LendingPad to grant access the application.')) {
        return;
      }

      if (this.isGranted('ROLE_SHOW_LOAN_CHECKLIST') && !this.ui.loanCheckListIsReviewed) {
        // TODO: show modal and update this.ui.loanCheckListIsReviewed if user have reviewed (checked) all items in the loan checklist
      }

      this.ui.form.loading = true;
      let formData = new FormData();

      for (let file of this.stips.uploaded) {
        formData.append(`stips[]`, file);
      }

      let data = {
        ...this.loan,
        lender: this.loan.lender?.value ?? null,
        realtor: this.loan.realtor?.id ?? null,
        LOofRecord: this.loan.LOofRecord?.value ?? null,
        author: this.loan.author?.value ?? null,
        processor: this.loan.processor?.value ?? null,
        preferredTitleCompany: this.loan.preferredTitleCompany?.value ?? null,
        isEnhancedProcessing: this.ui.selectedWorkflow.value === 'processor',
        waivingUnderwriting: this.loan.processor?.value ?? null,
        vaExempt: this.loan.vaExempt?.value ?? null,
        propertyState: this.isReferralLoan ? this.loan.state?.value : this.loan.propertyState,
        isReferralLoan: this.isReferralLoan,
        discloseRateType: this.ui.selectedWorkflow.value === 'processor'
          ? (this.loan.discloseRateType ? this.loan.discloseRateType.value : null)
          : null,
      };

      formData.append("loan", JSON.stringify(data));
      formData.append('isStoreCalled', 1);
      this.$http.post(`/api/v1/loans/new`, formData)
        .then((res) => {
          if (this.lendingpad.selectedBorrower !== null && this.lendingpad.selectedBorrower !== false) {
            this.submitLendingPadLoan(this.lendingpad.borrower.id, res.data.id);
          }

          this.stips.uploaded = [];
          this.ui.form.isError = false;
          this.ui.form.isSuccess = true;
          this.$store.commit("saveLoan", false);
          this.pushAlert('success', 'New Origination added')
          this.loadFormData();
          setTimeout(() => {
            this.ui.form.isSuccess = false;
          }, 1500);
          this.$emit("loanCreated");
        })
        .catch(e => {
          this.ui.form.isSuccess = false;
          this.ui.form.isError = true;
          if (e.response.data.errors) {
            this.ui.form.errors = e.response.data.errors;
          }
          this.ui.form.error = this.getFirstErrorFromResponse(e.response);
          let container = this.$el.querySelector("#new-origination-form-modal-block");
          this.pushAlert('error', e.response.data.message)
          this.$nextTick(() => {
            container.scrollTop = 1000 + container.scrollHeight;
          });
        })
        .finally(() => {
          this.ui.form.loading = false;
        });
    },
    submitLendingPadLoan(loanId, loanSubmissionId) {
      if (loanId) {
        this.$http
          .get(`/api/v1/loans/lendingpad/${loanId}/success/${loanSubmissionId}`)
          .catch(() => {
            this.pushAlert('error', 'Something went wrong.. Please refresh page and try again.')
          });
      }
    },
    loadFormData() {
      this.ui.loan.loading = true;
      this.$http
        .get(`/api/v1/loans/new`, {
          params: {isReferralLoan: this.isReferralLoan}
        })
        .then((res) => {
          this.formData.lenders = res.data.lenders
          this.formData.companies = res.data.escrowCompanies
          this.formData.agents = res.data.agents
          this.loan.LOofRecord = res.data.LOofRecord
          this.formData.LOofRecordOptions = res.data.LOofRecordOptions
          this.formData.processors = res.data.processors
          this.formData.enhancedProcessors = res.data.enhancedProcessors
          this.formData.states = this.isReferralLoan ? Object.values(res.data.states) : res.data.states
          this.formData.sourceOptions = res.data.sourceOptions
          this.formData.processorOptions = this.formData.processors
          this.formData.realtors.push(...res.data.realtors)
          this.formData.listingAgents.push(...res.data.listingAgents)
          this.formData.buyingAgents.push(...res.data.buyingAgents)
          this.loan.feesForBorrower = ''
          this.checkLenderType(this.loan.lenderType);
        })
        .catch(() => {
          this.ui.loan.isError = true
        })
        .finally(() => {
          this.ui.loan.loading = false
        })
    },
    submitStips(files) {
      this.stips.uploaded = this.stips.uploaded.concat(files);
    },
    deleteStip(id) {
      if (confirm("Are you sure?")) {
        this.$http.delete(`/api/v1/stips/delete/${id}`);
        this.stips.received = this.stips.received.filter(
          stip => stip.id !== id
        );
      }
    },
    selectBorrower() {
      this.lendingpad.borrowers = [];
      this.lendingpad.showBlock = true;
      this.lendingpad.loading = true;
      this.$http
        .get(`api/v1/loans/lendingpad`, {
          params: {
            offset: this.paging.offset,
            limit: this.paging.limit
          }
        })
        .then(res => {
          this.lendingpad.loading = false;
          if (res.data.data.length > 0) {
            this.lendingpad.notfound = false;
            this.lendingpad.borrowers = res.data.data;
            this.paging = res.data.paging;
          } else {
            this.lendingpad.notfound = true;
          }
        });
    },
    selectRealtor(realtor) {
      if (realtor.id === 'new_realtor') { // Open modal to create a new realtor
        this.ui.realtorModal = true;
        this.loan.realtor = null
      }
    },
    closeListingAgentModal(realtor = null) {
      if (realtor) {
        let option = {
          id: realtor.id,
          fullName: realtor.fullName
        };
        this.formData.listingAgents.push(option);
        this.loan.listingAgents.push(option)
        this.loan.listingAgents = this.loan.listingAgents.filter((el) => {
          return el.id !== 'new_agent'
        })
      } else {
        this.loan.listingAgents = null
      }

      this.ui.listingAgentModal = false;
    },
    closeBuyingAgentModal(agent = null) {
      if (agent) {
        let option = {
          id: agent.id,
          fullName: agent.fullName
        };
        this.formData.buyingAgents.push(option);
        this.loan.buyingAgents.push(option);
        this.loan.buyingAgents = this.loan.buyingAgents.filter((el) => {
          return el.id !== 'new_agent'
        })
      } else {
        this.loan.buyingAgent = null
      }

      this.ui.buyingAgentModal = false;
    },
    closeRealtorModal(realtor = null) {
      if (realtor) {
        let option = {
          id: realtor.id,
          fullName: realtor.fullName
        };
        this.formData.realtors.push(option);
        this.loan.realtor = option
      } else {
        this.loan.realtor = null
      }

      this.ui.realtorModal = false;
    },
    selectListingAgent(agent) {
      if (agent.id === 'new_agent') { // Open modal to create a new realtor
        this.loan.listingAgents = [];
        this.ui.listingAgentModal = true;
      }
    },
    selectBuyingAgent(agent) {
      if (agent.id === 'new_agent') { // Open modal to create a new realtor
        this.ui.buyingAgentModal = true;
        this.loan.buyingAgents = []
      }
    },
    loadMoreLendingPadRecord() {
      this.ui.loadingPaging = true;
      this.$http
        .get(`api/v1/loans/lendingpad`, {
          params: {
            offset: this.paging.offset,
            limit: this.paging.limit
          }
        })
        .then(res => {
          if (res.data.data.length > 0) {
            this.lendingpad.borrowers.push(...res.data.data);
            this.paging = res.data.paging;
          }
        })
        .finally(() => {
          this.ui.loadingPaging = false;
        });
    },
    selectBorrowerById(borrowerId) {
      this.ui.loan.loading = true;
      this.lendingpad.selectedBorrower = borrowerId;
      this.lendingpad.showBlock = false;
      this.lendingpad.loadBorrower = true;
      this.$http
        .get(`/api/v1/loans/lendingpad/byid/${borrowerId}`)
        .then(res => {
          this.ui.loan.loading = false;
          this.lendingpad.loadBorrower = false;
          this.lendingpad.borrower = res.data.profile;
          this.setupNewOriginationForm(res.data);
        });
    },
    setupNewOriginationForm: function (data) {
      this.formatNewOriginationForm();
      let borrowerInfo = data.borrower;
      let coBorrowerInfo = data.coborrower;
      let loanInfo = data.loan;
      let processor = data.loanInfo;

      if (Object.keys(borrowerInfo).length > 0) {
        this.loan.borrower.firstName = borrowerInfo.firstName;
        this.loan.borrower.lastName = borrowerInfo.lastName;
        this.loan.borrower.email = borrowerInfo.email;
      }

      if (Object.keys(coBorrowerInfo).length > 0) {
        this.loan.borrower.coFirstName = coBorrowerInfo.firstName;
        this.loan.borrower.coLastName = coBorrowerInfo.lastName;
      }

      if (Object.keys(loanInfo).length > 0) {
        this.loan.borrower.creditScore = loanInfo.creditScore;
        this.loan.productType = loanInfo.loanType;
        // this.loan.number = loanInfo.loanNumber
        this.loan.productAndTerm = loanInfo.term > 30 ? loanInfo.term / 12 : loanInfo.term;
        this.loan.amount = loanInfo.loanAmount;
        this.loan.rate = loanInfo.noteRate;
        this.loan.occupancy = loanInfo.occupancy;
        this.loan.propertyValue = loanInfo.propertyValue;
        this.loan.propertyState = loanInfo.propertyState;
        this.loan.propertyZip = loanInfo.propertyZip;
        this.loan.lendingpadNumber = loanInfo.loanNumber;
        if (loanInfo.lender) {
          this.loan.lender = { label: loanInfo.lender };
        } else {
          if (this.loan.lenderType === 'Correspondent') {
            this.loan.lender = this.formData.lenders.filter((el) => { if(el.label === 'UWM') {return el;}})[0]
          }
        }
        this.loan.payingOffMortgagesNumber = loanInfo.mortgageInsurance
          ? loanInfo.mortgageInsurance
          : 0;
      }

      this.loan.processor = processor.processor;
    },
    formatNewOriginationForm() {
      this.loan.borrower.firstName = null;
      this.loan.borrower.lastName = null;
      this.loan.borrower.coFirstName = null;
      this.loan.borrower.coLastName = null;
      this.loan.borrower.creditScore = null;
      this.loan.borrower.email = null;
      this.loan.productType = null;
      // this.loan.number = null;
      this.loan.productAndTerm = null;
      this.loan.amount = null;
      this.loan.rate = null;
      this.loan.occupancy = null;
      this.loan.propertyType = null;
      this.loan.propertyValue = null;
      this.loan.propertyState = null;
      this.loan.propertyZip = null;
      this.loan.lendingpadNumber = null;
      this.loan.lender = null;
      this.loan.payingOffMortgagesNumber = null;
      this.loan.processor = null;
    },
    closeModal() {
      this.formatNewOriginationForm();
      this.$emit("close");
    },
    removeData() {
      this.formatNewOriginationForm();
      this.lendingpad = {
        loading: false,
        notfound: false,
        showSelect: false,
        showBlock: false,
        confirmBlock: false,
        borrowers: [],
        selectedBorrower: null,
        loadBorrower: false,
        borrower: {
          imageName: "NA"
        }
      };
    },
    checkLenderTags(lender) {
      this.ui.lenderOfReverse = false;
      this.ui.lenderHasTagSecond = false;
      this.ui.isHighCostMortgage = false;
      this.loan.lienPosition = this.formData.lienPositionOptions[0];

      lender.tags.map(el => {
        if (el.name === "Reverse") {
          this.ui.lenderOfReverse = true;
        }

        if (el.name === "Second") {
          this.ui.lenderHasTagSecond = true;
        }

        if (el.name === "Non-QM" || el.name === "Non QM") {
          this.ui.isHighCostMortgage = true;
        }
      });
    },
    loadMoreLPRecords() {
      if (
        this.paging.total > 0 &&
        this.lendingpad.borrowers.length < this.paging.total
      ) {
        this.paging.offset = this.paging.offset + 10;
        this.loadMoreLendingPadRecord();
      }
    },
    scrollEventForLoadMore() {
      let element = document.querySelector(".select-from-lendingpad");
      var scrollerEndPoint = element.scrollHeight - element.clientHeight;
      var divScrollerTop = element.scrollTop;
      if (
        divScrollerTop === scrollerEndPoint &&
        this.lendingpad.showBlock &&
        !this.ui.loadingPaging
      ) {
        this.loadMoreLPRecords();
      }
    },
    checkLenderType(val) {
      if (val === 'Correspondent') {
        this.loan.lender = this.formData.lenders.filter((el) => {
          if (el.label === 'UWM') {
            return el;
          }
        })[0]
      } else {
        this.loan.lender = null
      }
    },
    confirmAddManually(val) {
      this.lendingpad.confirmBlock = false
      this.lendingpad.showBlock = !val
    },
    toggleProcessors(selectedOption) {
      this.ui.selectedWorkflow = selectedOption
      this.formData.processorOptions = selectedOption && selectedOption.value === 'processor'
        ? this.formData.enhancedProcessors
        : this.formData.processors
      this.loan.processor = null
    },
    borrowerFullName(loan) {
      return loan ? `${loan.borrower.firstName} ${loan.borrower.lastName}`.trim() : '';
    },
    selectState(option) {
      this.loan.LOofRecord = option.agents[0]
    },
    closeAssignRealtorsModal(data) {
      if (!data) {
        this.ui.showAssignRealtorsModal = false
        return
      }
      const listingAgents = []
      const buyingAgents = []

      if (data.listingAgent)  listingAgents.push(...data.listingAgent)

      if (data.listingAgentTC)  listingAgents.push(data.listingAgentTC)

      if (data.buyingAgent) buyingAgents.push(...data.buyingAgent)

      if (data.buyingAgentTC) buyingAgents.push(data.buyingAgentTC)

      console.log(listingAgents)
      console.log(buyingAgents)

      this.loan.listingAgents = listingAgents
      this.loan.buyingAgents = buyingAgents

      this.ui.showAssignRealtorsModal = false
    },
    removeListingAgent(id = null) {
      this.loan.listingAgents = this.loan.listingAgents.filter(agent => agent.id !== id)
    },
    removeBuyingAgent(id = null) {
      this.loan.buyingAgents = this.loan.buyingAgents.filter(agent => agent.id !== id)
    },
  },
  filters: {
    timeAgo: function (value) {
      if (value) {
        return moment(String(value)).fromNow();
      }
    }
  },
  computed: {
    loanNumberPlaceHolder() {
        if (this.loan.lenderType === this.formData.lenderTypes[1]) {
            return '';
        }
        return 'Optional';
    },
    loan: {
      get() {
        return this.$store.state.loansModal;
      }
    },
    tableBorrowerSearch() {
      return this.lendingpad.borrowers.filter(borrower => {
        return borrower.name
          .toLowerCase()
          .includes(this.filter.query.toLowerCase());
      });
    },
    showAttachmentType() {
      let type = this.loan.propertyType;
      return (
        type === "Condominium" ||
        type === "Site Condo" ||
        type === "Manufactured Condo Single Wide" ||
        type === "Manufactured Condo Multi Wide"
      );
    },
    isDisclosing() {
      if (this.loan.lender && this.loan.lender.label === "UWM" && this.ui.selectedWorkflow.value === "mlo") {
        return true;
      }
      return false;
    }
  },
  created() {
    this.loadFormData();
    // this.selectBorrower();
  },
  mounted() {
    document.querySelector("html").style.overflow = "hidden";
    const scrollbar = document.getElementById(
      "new-origination-form-modal-block"
    );
    const lenderScrollbar = document.querySelector(".select-from-lendingpad");
    this.ui.ps = new PerfectScrollbar(scrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
    this.ui.psLender = new PerfectScrollbar(lenderScrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
    lenderScrollbar.addEventListener("scroll", this.scrollEventForLoadMore);
  },
  beforeDestroy() {
    this.$store.commit("saveLoan", this.loan);
    document.querySelector("html").style.overflow = "initial";
  }
};
</script>
<style lang="scss" scoped>
.modal-block {
  &.select-from-lendingpad {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

#lender-scroll {
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
}

.check-btn.small {
  height: 45px;
}

hr {
  color: #e7e8e8;
}

.form-check {
  input.form-check-input {
    height: 18px;
    width: 18px;
    max-width: 18px;
    margin-right: 10px;
  }
}

.other-options {
  .form-check {
    border: 1px solid rgba(231, 232, 232, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    height: 43px;
    width: fit-content;
    padding: 0 12px;
    margin-bottom: 12px;

    &.active {
      border: 1px solid rgba(1, 122, 255, 1);
    }

    input {
      margin: 0 10px 0 0;

      &:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
      }
    }

    label {
      color: rgba(0, 0, 0, 1);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      font-weight: normal;
      white-space: nowrap;
      margin: 0;
    }
  }
}

.borrower-selected,
.borrowers-list {
  tbody {
    tr {
      &:hover {
        cursor: pointer;
      }

      .conventional {
        opacity: 0.5;
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
      }
    }
  }

  .borrower-avatar {
    height: 55px;
    width: 55px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    background-color: #ec2254;
    text-align: center;

    div {
      color: #ffffff;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      margin: 50% 0 0 50%;
      transform: translate(-50%, -50%);
    }
  }

  .grey {
    background-color: #d8d8d8;

    div {
      color: #4a4a4a;
    }
  }

  .borrower-from {
    opacity: 0.46;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }

  .borrower-name,
  .borrower-conventional {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    font-weight: normal;
  }

  .borrower-conventional {
    margin-left: 12px;
    opacity: 0.7;
  }

  .borrower-info {
    margin-left: 24px;
  }
}

.modal-search-block {
  background-color: #ffffff;
  max-width: 100%;
  margin: 70px 0 0 0;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid #e7e8e8;

  .search-wrapper {
    background-color: #ffffff;
    max-width: 100%;
    border-radius: 0;
    border-bottom: solid 1px #0078ff;
    margin: 0;

    input {
      border-radius: 0;
      border: none;
    }
  }
}

.btn-remove {
  border: 1px solid #d4d4d4;
  opacity: 0.7;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;

  &:hover {
    background: lightgrey;
    opacity: 1;
  }
}

.add-manually {
  .color-light-grey {
    opacity: 0.7;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 16px;
    font-weight: normal;
  }
}

.modal-body-container::v-deep .multiselect {
  width: auto;

  &.selected {
    .multiselect__tags {
      background-color: #f3f3f4;
    }

    .multiselect__single {
      background-color: #f3f3f4;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
    }

    input {
      background-color: #f3f3f4;
    }
  }

  .multiselect__tag {
    margin-bottom: 0 !important;
    margin-top: 5px !important;
  }


  &.multiselect--active {
    .multiselect__tags {
      transition: all 0.3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }


  &__tags {
    display: flex;
    align-items: center;
    padding: 12px 40px 12px 15px;
    transition: all 0.3s;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__single {
    padding: 0;
  }

  &__select {
    &:before {
      top: 17%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: rgba(0, 0, 0, 0.46);
    margin-bottom: 0;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all 0.3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }

  &__placeholder {
    padding: 0;
    margin: 0;
  }
  &__input {
    padding: 0;
    margin: 0;
  }
}

.btn-link {
  color: #017aff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: normal;
}

.modal-footer {
  .bg-green {
    max-width: 156px;
    width: 100%;
  }

  .btn-cancel {
    max-width: 90px;
    width: 100%;
  }
}

.modal-form {
  position: relative;
  width: 772px;
}

.w-76 {
  width: 76% !important;
  max-width: 76% !important;
}

::v-deep {
  .scanned-doc {
    input {
      left: 0;
    }
  }
}

.confirm-alert {
  margin-top: 5rem !important;
}

.processor-details {
  border: 1px solid #e7e8e8;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;

  img {
    height: 52px;
    border-radius: 50px;
    margin-right: 10px;
  }
}
</style>
